
  import { Component, Prop, Vue } from 'vue-property-decorator'

  export class Correlation {
  _perc: number = 0
  _amount: number = 0

  constructor (private readonly total: number, initial = 40) {
    this.perc = initial
  }

  get debt () {
    return this.total - this.amount
  }

  get perc () {
    return this._perc * 100
  }

  set perc (perc) {
    this._perc = perc / 100
    this._amount = this.total * this._perc
    console.log('CorrelatedFields:perc', perc, this.perc, this.amount)
  }

  get amount () {
    return this._amount
  }

  set amount (amount) {
    this._amount = amount
    this._perc = amount / this.total
    console.log('CorrelatedFields:amount', amount, this.perc, this.amount)
  }
  }

@Component({})
  export default class CorrelatedFields extends Vue {
  @Prop({
    type: Object,
default: () => {
    },
  }) value: Correlation

  @Prop({ type: Number, required: true }) total: number
  @Prop({ type: Number, default: 40 }) initial: number

  correlation = {} as Correlation
  @Prop({ type: Number, default: 40 }) min: number

  created () {
    this.correlation = new Correlation(this.total, this.initial)
    this.value = new Correlation(this.total, this.initial)
    this.emit()
  }

  private emit () {
    this.$emit('input', this.value)
  }

  setAmount ({ target: { value } }) {
    this.value.amount = value
    this.emit()
  }

  setPerc (e) {
    this.value.perc = e
    this.emit()
  }

  get color () {
    const { value: { perc }, min } = this
    if (perc >= min * 1.3) return 'green'
    if (perc >= min) return 'primary'
    if (perc >= min / 3 * 2) return 'warning'
    return 'error'
  }
  }
